import {
  createOwnUpComponent,
  createOwnUpStyle,
  OwnUpBox
} from '@rategravity/own-up-component-library-legacy';
import React from 'react';
import RedSquiggleSVG from '../../../images/red-squiggle.svg';

const WrapperStyle = createOwnUpStyle({
  display: 'flex',
  justifyContent: 'center',
  margin: '20px 0'
});

const Wrapper = createOwnUpComponent(OwnUpBox, WrapperStyle);

export const RedSquiggleSeparator = () => (
  <Wrapper>
    <img src={RedSquiggleSVG} alt="" style={{ margin: 'auto', display: 'block', width: 70 }} />
  </Wrapper>
);
