import { buildMediaQueries } from '@rategravity/marketing-components';
import {
  createOwnUpComponent,
  createOwnUpStyle,
  fonts,
  OwnUpBox,
  OwnUpHeader
} from '@rategravity/own-up-component-library-legacy';
import React from 'react';
import styled from 'styled-components';
import GreenArrowSVG from '../../../images/green-arrow.svg';
import HeroBullet from '../../../images/icons/hero-bullet.svg';
import { colors } from '../../../modules/colors';
import { SectionHeaderMixin } from '../../typography';
import { HeaderWithImage } from '../background-with-image';
import { HorizontalColorSplitSVG } from '../color-split-svg';
import { CTAButton } from '../cta-button';

const TitleText = styled(OwnUpHeader)`
  ${SectionHeaderMixin}
  letter-spacing: 1px;
  text-align: center;
  ${buildMediaQueries('medium', {
    fontSize: '45px'
  })}
  ${buildMediaQueries('smallAndDown', {
    letterSpacing: '.5px',
    fontSize: '33px',
    fontFamily: "'TiemposHeadline-Medium', 'Palatino', 'Times New Roman', 'serif'",
    padding: '65px 0 40px 0',
    margin: 0
  })}
`;

const TextWrapperStyle = createOwnUpStyle({
  margin: '0 auto',
  ...fonts.GRAPHIK_REGULAR,
  fontFamily: "'Graphik-Regular', 'Arial', 'sans-serif'",
  lineHeight: 2,
  color: colors.PRIMARY,
  fontSize: 15,
  textAlign: 'left',
  display: 'flex',
  flexDirection: 'column'
});

const bullets = [
  'Shop a marketplace of top lenders',
  'Zero unwanted calls or emails',
  'No impact to your credit'
];

const HeroListStyle = createOwnUpStyle({
  ...TextWrapperStyle,
  paddingInlineStart: 0,
  '& > li': {
    display: 'flex',
    listStyle: 'none',
    ':before': {
      content: "''",
      display: 'inline-block',
      margin: '5px 10px',
      background: `url(${HeroBullet}) no-repeat top center `,
      backgroundSize: 'contain',
      minWidth: 15
    }
  }
});

const HeroList = createOwnUpComponent('ul', HeroListStyle);

const HeroText = () => (
  <HeroList>
    {bullets.map((bullet, i) => (
      <li key={`bullet-${i}`}>{bullet}</li>
    ))}
  </HeroList>
);

const SubSectionTextStyle = createOwnUpStyle({
  height: 132,
  ...fonts.GRAPHIK_REGULAR,
  fontFamily: "'Graphik', 'Arial', 'sans-serif'",
  backgroundColor: colors.DARKER_AQUA,
  color: colors.LOGO_SHADOW,
  fontSize: 17,
  fontWeight: 500,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  padding: '0 40px',
  variants: {
    smallAndDown: {
      lineHeight: '130%',
      height: 115
    }
  }
});

const SubSectionText = createOwnUpComponent('div', SubSectionTextStyle);

const HeroSubSection = () => (
  <SubSectionText>Here&apos;s why homebuyers and homeowners love us...</SubSectionText>
);

const HeroContentWrapper = createOwnUpComponent('section', {});

const HeroWrapperStyle = createOwnUpStyle({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  paddingTop: 30,
  variants: {
    medium: {
      padding: '0 60px'
    },
    smallAndDown: {
      padding: '0 30px'
    }
  }
});

const HeroWrapper = createOwnUpComponent('div', HeroWrapperStyle);

const HorizontalColorSplitWrapperStyle = createOwnUpStyle({
  margin: '-43px 0',
  variants: {
    medium: {
      margin: '-35px 0'
    },
    smallAndDown: {
      margin: '-30px 0'
    }
  }
});

const HorizontalColorSplitWrapper = createOwnUpComponent(
  OwnUpBox,
  HorizontalColorSplitWrapperStyle
);

const CTAButtonWrapperStyle = createOwnUpStyle({
  marginBottom: 40,
  variants: {
    smallAndDown: {
      marginTop: 40,
      marginBottom: 85
    }
  }
});

const CTAButtonWrapper = createOwnUpComponent('div', CTAButtonWrapperStyle);

const LargeHeaderWrapper = styled.div`
  ${buildMediaQueries('smallAndDown', {
    display: 'none'
  })}
`;

const SmallHeaderWrapper = styled.div`
  ${buildMediaQueries(['medium', 'large'], {
    display: 'none'
  })}
`;

export const Hero = () => {
  return (
    <HeaderWithImage>
      <HeroContentWrapper aria-label="Hero">
        <HeroWrapper>
          <TitleText variant="title">
            <LargeHeaderWrapper>
              Find your best mortgage.
              <br />
              Save time and money.
            </LargeHeaderWrapper>
            <SmallHeaderWrapper>Find your best mortgage. Save time and money.</SmallHeaderWrapper>
          </TitleText>
          <HeroText />
          <CTAButtonWrapper style={{ marginBottom: 40 }}>
            <CTAButton text="Shop Our Marketplace" />
          </CTAButtonWrapper>
        </HeroWrapper>
        <HeroSubSection />
        <HorizontalColorSplitWrapper>
          <HorizontalColorSplitSVG
            topColor={colors.DARKER_AQUA}
            bottomColor={colors.WHITE}
            SVG={GreenArrowSVG}
            height={86}
          />
        </HorizontalColorSplitWrapper>
      </HeroContentWrapper>
    </HeaderWithImage>
  );
};
