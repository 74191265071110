import {
  createOwnUpComponent,
  createOwnUpStyle,
  OwnUpBox
} from '@rategravity/own-up-component-library-legacy';
import React, { PropsWithChildren } from 'react';

import HouseSVG from '../../../images/house.svg';
import { colors } from '../../../modules/colors';
import { FullWidthField } from '../../full-width-field';

const HomeImageStyle = createOwnUpStyle({
  backgroundImage: `url(${HouseSVG})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: '307px 227px',
  backgroundPosition: '90% 260px',
  variants: {
    medium: {
      backgroundPosition: '95% 57%'
    },
    small: {
      backgroundPosition: '120% 64%'
    },
    xsmall: {
      backgroundPosition: '175% 64%'
    }
  }
});

const Home = createOwnUpComponent(OwnUpBox, HomeImageStyle);

export const HeaderWithImage = ({ children }: PropsWithChildren<{}>) => (
  <FullWidthField backgroundColor={colors.AQUA}>
    <Home>{children}</Home>
  </FullWidthField>
);
