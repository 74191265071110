import { OwnUpBox } from '@rategravity/own-up-component-library-legacy';
import React from 'react';

/**
 * Component that renders a centered SVG on a background
 * that is split horizontally between two colors.
 */
export const HorizontalColorSplitSVG = ({
  topColor,
  bottomColor,
  SVG,
  height
}: {
  topColor: string;
  bottomColor: string;
  SVG: string;
  height: number;
}) => (
  <OwnUpBox
    style={{
      background: `linear-gradient(${topColor} 50%, ${bottomColor} 50%)`,
      height
    }}
  >
    <img src={SVG} alt="" style={{ margin: 'auto', display: 'block', height: '100%' }} />
  </OwnUpBox>
);
